// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/page-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/project-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-project-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-projects-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/projects-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-projects-query-tsx" */),
  "component---src-pages-apps-fighting-trainer-index-js": () => import("./../../../src/pages/apps/fighting-trainer/index.js" /* webpackChunkName: "component---src-pages-apps-fighting-trainer-index-js" */),
  "component---src-pages-en-privacy-policy-ft-js": () => import("./../../../src/pages/en/privacy-policy-ft.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-ft-js" */),
  "component---src-pages-games-hyper-time-disruptor-index-js": () => import("./../../../src/pages/games/hyper-time-disruptor/index.js" /* webpackChunkName: "component---src-pages-games-hyper-time-disruptor-index-js" */)
}

